// src/components/MenuPreview.js
import React from "react";

function MenuPreview({ data }) {
  return (
    <div
      style={{
        backgroundColor: data.backgroundColor,
        color: data.textColor,
        fontFamily: data.fontFamily || "Roboto",
        padding: "20px",
        borderRadius: "8px",
        maxWidth: "700px",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
      }}
    >
      {/* Logo Önizleme */}
      {data.logo && (
        <div
          style={{
            display: "flex",
            justifyContent: data.logoPosition || "center",
            marginBottom: "20px",
          }}
        >
          <img
            src={data.logo}
            alt="Logo"
            style={{
              width: `${data.logoSize || 50}px`,
              height: `${data.logoSize || 50}px`,
              objectFit: "contain",
            }}
          />
        </div>
      )}

      <h1
        style={{ fontSize: "2em", textAlign: "center", marginBottom: "20px" }}
      >
        Yemek Menüsü
      </h1>
      {data.categories.map((category, index) => (
        <div key={index}>
          <h2 style={{ fontSize: "1.5em", marginBottom: "10px" }}>
            {category.name}
          </h2>
          {category.items.map((item, idx) => (
            <div
              key={idx}
              style={{
                marginBottom: "10px",
                paddingBottom: "10px",
                borderBottom: "1px solid #ddd",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <span style={{ fontWeight: "bold" }}>{item.name}</span>
                <span>{item.price}₺</span>
              </div>
              <p style={{ color: data.descriptionColor }}>{item.description}</p>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
}

export default MenuPreview;
