// src/App.js
import React, { useState, useEffect } from "react";
import MenuPreview from "./components/MenuPreview";
import AdminPanel from "./components/AdminPanel";
import { PDFDownloadLink } from "@react-pdf/renderer";
import PDFDocument from "./components/PDFDocument";

import "./index.css";

function App() {
  const [data, setData] = useState({
    logo: null,
    categories: [
      {
        name: "Başlangıçlar",
        items: [
          { name: "Çorba", description: "Lezzetli bir çorba", price: "10" },
        ],
      },
    ],
    backgroundColor: "#ffffff",
    textColor: "#000000",
    descriptionColor: "#666666",
    logoSize: 60,
    fontFamily: "Roboto",
  });

  const [darkMode, setDarkMode] = useState(false);

  useEffect(() => {
    if (darkMode) {
      document.body.style.backgroundColor = "#1a1a1a";
      document.documentElement.classList.add("dark");
    } else {
      document.body.style.backgroundColor = "#f0f0f0";
      document.documentElement.classList.remove("dark");
    }
  }, [darkMode]);

  return (
    <div
      className={`flex flex-col space-y-4 p-4 ${
        darkMode ? "text-gray-100" : "text-gray-800"
      }`}
    >
      <div className="flex space-x-4 h-screen overflow-y-auto">
        <AdminPanel
          data={data}
          setData={setData}
          darkMode={darkMode}
          setDarkMode={setDarkMode}
        />

        <div className="relative flex flex-col items-center w-full">
          <div className="flex justify-end w-full p-4 space-x-4">
            {/* Dark Mode Toggle */}
            <label className="flex items-center space-x-2 cursor-pointer">
              <span>Açık</span>
              <input
                type="checkbox"
                checked={darkMode}
                onChange={() => setDarkMode(!darkMode)}
                className="toggle-checkbox hidden"
              />
              <span className="toggle-switch w-10 h-5 flex items-center bg-gray-300 rounded-full p-1">
                <span
                  className={`bg-white w-4 h-4 rounded-full shadow-md transform ${
                    darkMode ? "translate-x-5" : ""
                  }`}
                ></span>
              </span>
              <span>Koyu</span>
            </label>

            {/* PDF İndir Button */}
            <PDFDownloadLink
              document={<PDFDocument data={data} />}
              fileName="menu.pdf"
            >
              {({ loading }) => (
                <button className="py-2 px-4 bg-blue-600 text-white font-semibold rounded-lg shadow-md hover:bg-blue-700 transition">
                  {loading ? "PDF Hazırlanıyor..." : "PDF İndir"}
                </button>
              )}
            </PDFDownloadLink>
          </div>

          <div className="menu-preview-container mt-4">
            <MenuPreview data={data} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
