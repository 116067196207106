// src/components/AdminPanel.js
import React, { useState } from "react";
import ContentTab from "./ContentTab";
import CustomizationTab from "./CustomizationTab";

function AdminPanel({ data, setData, darkMode, setDarkMode }) {
  const [activeTab, setActiveTab] = useState("content");

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const handleLogoUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const logoUrl = URL.createObjectURL(file);
      setData((prevData) => ({ ...prevData, logo: logoUrl }));
    }
  };

  return (
    <div
      className={`p-9 shadow-md rounded-lg space-y-6 min-w-[40%] max-w-[50%] ${
        darkMode ? "bg-gray-800 text-gray-200" : "bg-gray-100 text-gray-700"
      }`}
    >
      <div className="flex space-x-4 mb-4">
        <button
          className={`px-4 py-2 rounded ${
            activeTab === "content"
              ? "bg-blue-500 text-white"
              : "bg-gray-200 dark:bg-gray-700 dark:text-gray-200"
          }`}
          onClick={() => handleTabChange("content")}
        >
          İçerik Girişi
        </button>
        <button
          className={`px-4 py-2 rounded ${
            activeTab === "customize"
              ? "bg-blue-500 text-white"
              : "bg-gray-200 dark:bg-gray-700 dark:text-gray-200"
          }`}
          onClick={() => handleTabChange("customize")}
        >
          Özelleştirme
        </button>
      </div>

      {activeTab === "content" ? (
        <ContentTab data={data} setData={setData} />
      ) : (
        <CustomizationTab
          data={data}
          setData={setData}
          darkMode={darkMode}
          setDarkMode={setDarkMode}
          handleLogoUpload={handleLogoUpload} // Logo yükleme fonksiyonunu CustomizationTab’a aktar
        />
      )}
    </div>
  );
}

export default AdminPanel;
