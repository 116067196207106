// src/components/PDFDocument.js
import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font,
} from "@react-pdf/renderer";

// Fontları dinamik olarak kaydet
Font.register({
  family: "Roboto",
  src: "https://fonts.gstatic.com/s/roboto/v27/KFOmCnqEu92Fr1Me5Q.ttf",
});
Font.register({
  family: "Noto Sans",
  src: "https://fonts.gstatic.com/s/notosans/v27/o-0IIpQlx3QUlC5A4PNjG9w.ttf",
});
Font.register({
  family: "Lora",
  src: "https://fonts.gstatic.com/s/lora/v16/0QIhMX1D_JOuGQbT0gvTJPa787weuxJBkqg.ttf",
});

const PDFDocument = ({ data }) => {
  const selectedFont = data.fontFamily || "Roboto"; // Seçilen fontu kullan

  const styles = StyleSheet.create({
    page: {
      padding: 20,
      backgroundColor: data.backgroundColor || "#ffffff",
    },
    title: {
      fontSize: 24,
      marginBottom: 10,
      textAlign: "center",
      color: data.textColor || "#000000",
      fontFamily: selectedFont,
    },
    category: {
      marginBottom: 10,
    },
    categoryTitle: {
      fontSize: 14,
      marginBottom: 5,
      color: data.textColor || "#000000",
      fontFamily: selectedFont,
    },
    item: {
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      paddingBottom: 5,
      borderBottomWidth: 1,
      borderBottomColor: "#ddd",
      marginBottom: 8,
    },
    itemName: {
      fontSize: 12,
      color: data.textColor || "#000000",
      fontFamily: selectedFont,
    },
    itemDescription: {
      fontSize: 10,
      color: data.descriptionColor || "#666666",
      fontFamily: selectedFont,
      marginTop: 2,
    },
    itemPrice: {
      fontSize: 12,
      color: data.textColor || "#000000",
      fontFamily: selectedFont,
    },
    logo: {
      width: data.logoSize || 50,
      height: data.logoSize || 50,
      marginBottom: 10,
      alignSelf: data.logoPosition || "center",
    },
  });

  return (
    <Document>
      <Page style={styles.page}>
        {data.logo && <Image style={styles.logo} src={data.logo} />}
        <Text style={styles.title}>Yemek Menüsü</Text>
        {data.categories.map((category, index) => (
          <View key={index} style={styles.category}>
            <Text style={styles.categoryTitle}>{category.name}</Text>
            {category.items.map((item, idx) => (
              <View key={idx} style={styles.item}>
                <View>
                  <Text style={styles.itemName}>{item.name}</Text>
                  <Text style={styles.itemDescription}>{item.description}</Text>
                </View>
                <Text style={styles.itemPrice}>
                  {item.price} {data.currency || "₺"}
                </Text>
              </View>
            ))}
          </View>
        ))}
      </Page>
    </Document>
  );
};

export default PDFDocument;
