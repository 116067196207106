// src/components/ContentTab.js
import React from "react";
import { useDrag, useDrop } from "react-dnd";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { FaGripVertical } from "react-icons/fa";

function DraggableItem({
  item,
  categoryIndex,
  itemIndex,
  moveItem,
  duplicateItem,
  deleteItem,
  handleItemChange,
}) {
  const [, ref] = useDrag({
    type: "ITEM",
    item: { categoryIndex, itemIndex },
  });

  const [, drop] = useDrop({
    accept: "ITEM",
    hover(draggedItem) {
      if (draggedItem.itemIndex !== itemIndex) {
        moveItem(
          draggedItem.categoryIndex,
          draggedItem.itemIndex,
          categoryIndex,
          itemIndex
        );
        draggedItem.itemIndex = itemIndex;
      }
    },
  });

  return (
    <div
      ref={(node) => ref(drop(node))}
      className="p-2 border border-gray-300 rounded mb-2 flex items-center"
    >
      {/* Sürükle bırak ikonu */}
      <FaGripVertical className="mr-2 cursor-pointer text-gray-500" />

      {/* Yemek Adı Input */}
      <input
        type="text"
        value={item.name}
        onChange={(e) =>
          handleItemChange(categoryIndex, itemIndex, "name", e.target.value)
        }
        placeholder="Yemek Adı"
        className="p-1 mr-2 border rounded w-1/3 dark:bg-gray-700 dark:text-white"
      />

      {/* Açıklama Input */}
      <input
        type="text"
        value={item.description}
        onChange={(e) =>
          handleItemChange(
            categoryIndex,
            itemIndex,
            "description",
            e.target.value
          )
        }
        placeholder="Açıklama"
        className="p-1 mr-2 border rounded w-1/3 dark:bg-gray-700 dark:text-white"
      />

      {/* Fiyat Input */}
      <input
        type="text"
        value={item.price}
        onChange={(e) =>
          handleItemChange(categoryIndex, itemIndex, "price", e.target.value)
        }
        placeholder="Fiyat"
        className="p-1 mr-2 border rounded w-16 dark:bg-gray-700 dark:text-white"
      />

      {/* Kopyala ve Sil Butonları */}
      <button
        onClick={() => duplicateItem(categoryIndex, itemIndex)}
        className="text-blue-500 ml-2"
      >
        Kopyala
      </button>
      <button
        onClick={() => deleteItem(categoryIndex, itemIndex)}
        className="text-red-500 ml-2"
      >
        Sil
      </button>
    </div>
  );
}

const ContentTab = ({ data, setData }) => {
  const handleCategoryChange = (index, value) => {
    const updatedCategories = [...data.categories];
    updatedCategories[index].name = value;
    setData({ ...data, categories: updatedCategories });
  };

  const handleItemChange = (categoryIndex, itemIndex, field, value) => {
    const updatedCategories = [...data.categories];
    updatedCategories[categoryIndex].items[itemIndex][field] = value;
    setData({ ...data, categories: updatedCategories });
  };

  const addCategory = () => {
    const updatedCategories = [...data.categories, { name: "", items: [] }];
    setData({ ...data, categories: updatedCategories });
  };

  const addItem = (categoryIndex) => {
    const updatedCategories = [...data.categories];
    updatedCategories[categoryIndex].items.push({
      name: "",
      description: "",
      price: "",
    });
    setData({ ...data, categories: updatedCategories });
  };

  const duplicateItem = (categoryIndex, itemIndex) => {
    const updatedCategories = [...data.categories];
    const itemToDuplicate = {
      ...updatedCategories[categoryIndex].items[itemIndex],
    };
    updatedCategories[categoryIndex].items.push(itemToDuplicate);
    setData({ ...data, categories: updatedCategories });
  };

  const deleteItem = (categoryIndex, itemIndex) => {
    const updatedCategories = [...data.categories];
    updatedCategories[categoryIndex].items.splice(itemIndex, 1);
    setData({ ...data, categories: updatedCategories });
  };

  const clearAllItems = (categoryIndex) => {
    const updatedCategories = [...data.categories];
    updatedCategories[categoryIndex].items = [];
    setData({ ...data, categories: updatedCategories });
  };

  const moveItem = (
    sourceCatIndex,
    sourceItemIndex,
    targetCatIndex,
    targetItemIndex
  ) => {
    const updatedCategories = [...data.categories];
    const [movedItem] = updatedCategories[sourceCatIndex].items.splice(
      sourceItemIndex,
      1
    );
    updatedCategories[targetCatIndex].items.splice(
      targetItemIndex,
      0,
      movedItem
    );
    setData({ ...data, categories: updatedCategories });
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <h3 className="text-xl font-semibold mb-4">Yemek Menü İçeriği</h3>
      {data.categories.map((category, categoryIndex) => (
        <div key={categoryIndex} className="mb-4">
          <input
            type="text"
            value={category.name}
            onChange={(e) =>
              handleCategoryChange(categoryIndex, e.target.value)
            }
            placeholder="Kategori Adı"
            className="p-2 border rounded mb-2 w-full bg-gray-200 dark:bg-gray-700 dark:text-white border-gray-600"
          />
          {category.items.map((item, itemIndex) => (
            <DraggableItem
              key={itemIndex}
              item={item}
              categoryIndex={categoryIndex}
              itemIndex={itemIndex}
              moveItem={moveItem}
              duplicateItem={duplicateItem}
              deleteItem={deleteItem}
              handleItemChange={handleItemChange}
            />
          ))}
          <button
            onClick={() => addItem(categoryIndex)}
            className="text-blue-500"
          >
            + Yemek Ekle
          </button>
          <button
            onClick={() => clearAllItems(categoryIndex)}
            className="text-red-500 ml-4"
          >
            Tümünü Sil
          </button>
        </div>
      ))}
      <button onClick={addCategory} className="text-blue-500">
        + Kategori Ekle
      </button>
    </DndProvider>
  );
};

export default ContentTab;
