// src/components/CustomizationTab.js
import React from "react";

function CustomizationTab({ data, setData, handleLogoUpload }) {
  return (
    <div>
      <h2 className="text-xl font-semibold mb-4">Özelleştirme Ayarları</h2>

      {/* Logo Yükleme */}
      <div className="mb-4">
        <label className="block text-sm font-medium mb-2">Logo Yükle:</label>
        <input
          type="file"
          accept="image/*"
          onChange={handleLogoUpload}
          className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none"
        />
      </div>

      {/* Arka Plan Rengi Seçimi */}
      <div className="mb-4">
        <label className="block text-sm font-medium mb-2">
          Arka Plan Rengi:
        </label>
        <input
          type="color"
          value={data.backgroundColor}
          onChange={(e) =>
            setData((prevData) => ({
              ...prevData,
              backgroundColor: e.target.value,
            }))
          }
          className="w-full"
        />
      </div>

      {/* Metin Rengi Seçimi */}
      <div className="mb-4">
        <label className="block text-sm font-medium mb-2">Metin Rengi:</label>
        <input
          type="color"
          value={data.textColor}
          onChange={(e) =>
            setData((prevData) => ({ ...prevData, textColor: e.target.value }))
          }
          className="w-full"
        />
      </div>

      {/* Açıklama Yazısı Rengi */}
      <div className="mb-4">
        <label className="block text-sm font-medium mb-2">
          Açıklama Yazısı Rengi:
        </label>
        <input
          type="color"
          value={data.descriptionColor}
          onChange={(e) =>
            setData((prevData) => ({
              ...prevData,
              descriptionColor: e.target.value,
            }))
          }
          className="w-full"
        />
      </div>

      {/* Logo Boyutu */}
      <div className="mb-4">
        <label className="block text-sm font-medium mb-2">Logo Boyutu:</label>
        <input
          type="range"
          min="20"
          max="100"
          value={data.logoSize || 60}
          onChange={(e) =>
            setData((prevData) => ({ ...prevData, logoSize: e.target.value }))
          }
          className="w-full"
        />
      </div>

      {/* Yazı Tipi Seçimi */}
      <div className="mb-4">
        <label className="block text-sm font-medium mb-2">Yazı Tipi:</label>
        <select
          value={data.fontFamily}
          onChange={(e) =>
            setData((prevData) => ({ ...prevData, fontFamily: e.target.value }))
          }
          className="w-full p-2 border border-gray-300 rounded-lg bg-gray-50 focus:outline-none"
        >
          <option value="Roboto">Roboto</option>
          <option value="Noto Sans">Noto Sans</option>
          <option value="Lora">Lora</option>
        </select>
      </div>
    </div>
  );
}

export default CustomizationTab;
